import React, { useState, useEffect, useRef, useCallback } from 'react'
import 'react-dates/initialize'
import { DateRangePicker } from 'react-dates'
import Pagination from './Pagination'
import EntityLoader from './EntityLoader'
import EmptyGrid from './EmptyGrid'
import Tabs from './Tabs'
import DropMenu from './DropMenu'
import { Select, MenuItem, Box, Chip, Checkbox } from '@material-ui/core'
import Toggle from 'react-toggle'
import ModalAlert from './ModalAlert'
import moment from 'moment'
import debounce from 'lodash.debounce'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers'
import DropDownMenu from './DropDownMenu'
import FlashMessage from './FlashMessage'

export default function DynamicEntityIndex({
  appProps, initialItems, initialTotalPages, selectAll, entity, tabs, tableHeadings,
  itemRenderer, deletePath, itemsPath, exportPath, icon, noResourcesTitle, tags,
  disableDelete, fetchOnStart, initialTags, disableSelectTags, customDeleteAlert, setInitialItems, listColumns,
  disableCustomization, actions, dropMenuItems, columnTranslations, disableSearch,
  urlParams, disableReload, isExportable, onDeleteError, enableDateFilter, enableFutureDates, filters,
  initialTotalCount, updateTotalCount, enablePriceFilter, enableHourFilter, setSelectedAllItems, onDeleteSuccess, headline, hideHeadlineCount, headlineIcon,
  enableAdvancedFiltering
}) {
  const [currentTab, setCurrentTab] = useState((tabs && tabs.length) ? tabs[0].key : null)
  const [selectedItems, setSelectedItems] = useState([])
  const [loading, setLoading] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [items, _setItems] = useState(initialItems)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(initialTotalPages)
  const [totalCount, setTotalCount] = useState(initialTotalCount)
  const [currentTags, setCurrentTags] = useState(initialTags || [])
  const [showCustomize, _setShowCustomize] = useState(false)
  const [currentColumns, setCurrentColumns] = useState(tableHeadings || [])
  const [currentSortable, setCurrentSortable] = useState(null)
  const [sortingMode, setSortingMode] = useState(null)
  const [tempColumns, setTempColumns] = useState([])
  const [selectedDropMenuItem, setSelectedDropMenuItem] = useState(null)
  const [showExportAlert, setShowExportAlert] = useState(false)
  const [dateRange, setDateRange] = useState({
    start_date: moment().subtract(91, 'days'),
    end_date: moment(new Date())
  })
  const [priceRange, setPriceRange] = useState({
    minimum: null,
    maximum: null
  })
  const [hourRange, setHourRange] = useState({
    from: moment('2000-01-01 00:00', 'YYYY-MM-DD h:mm a'),
    to:   moment('2000-01-01 23:59', 'YYYY-MM-DD h:mm a'),
  })
  const [focusedInput, setFocusedInput] = useState(null)
  const [showFilters, setShowFilters] = useState(false)
  const [currentFilters, _setCurrentFilters] = useState({})
  const [showAdvancedFilterSection, setShowAdvancedFilterSection] = useState(enableAdvancedFiltering || false)
  const smallDevice = window.matchMedia('(max-width: 480px)').matches

  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const [massSelected, setMassSelected] = useState(null)
  const [selectedPages, setSelectedPages] = useState([])

  const didMountRef = useRef(null)
  const searchValueRef = useRef(searchValue)
  const priceRangeRef = useRef(priceRange)
  const dateRangeRef = useRef(dateRange)
  const flashMessageRef = useRef(null)
  const mergedColumnTranslations = { ...Website.translations.columns, ...(columnTranslations || {}) }
  const dropdownActions = []

  if (!disableDelete){
    dropdownActions.push({ icon: 'fa-trash', action: () => setShowDeleteAlert(true), text: Website.translations.deleteSelected, permissions: true })
  }

  if (isExportable) {
    dropdownActions.push({ icon: 'fa-file-excel', action: () => { if (!loading) { setShowExportAlert(true) } }, text: Website.translations.dataExport, permissions: true })
  }

  const priceRegex = /^(0|[1-9]\d*)?([.,]{1}\d{0,2})?/

  useEffect( () => {
    if (fetchOnStart) {
      getItems()
    }
  }, [priceRange, hourRange])

  useEffect( () => {
    if (enableDateFilter) {
      getItems()
    }
  }, [hourRange])

  useEffect( () => {
    if (Object.keys(currentFilters).length) {
      getItems()
    }
  }, [currentFilters])

  useEffect( () => {
    if (didMountRef.current) {
      getItems()
    }
  }, [showAdvancedFilterSection])

  useEffect( () => {
    _setItems(initialItems)
  }, [initialItems])

  useEffect( ()=> {
    document.addEventListener('keyup', event => {
      if (event.key === 'Escape') {
        setShowCustomize(false)
      }
    })
  }, [])

  useEffect( () => {
    didMountRef.current = true
  }, [])

  function setItems(state) {
    _setItems(state)
    if (setInitialItems) {
      setInitialItems(state)
    }
  }

  function setCurrentFilters(state) {
    _setCurrentFilters( prevState => ({ ...prevState, ...state }))
  }

  const debouncedSearch = useCallback(
    debounce(getItems, 700),
    [
      currentTab,
      currentTags,
      currentSortable,
      sortingMode,
      currentFilters,
      hourRange,
      dateRange,
      showAdvancedFilterSection
    ]
  )

  function changeInput(event) {
    const value = event.target.value
    setSearchValue(value)
    searchValueRef.current = value

    if (value.length > 2) {
      debouncedSearch()
    } else {
      debouncedSearch.cancel()
      if (!value.length) {
        getItems()
      }
    }
  }

  function handleChangePage(page){
    if (currentPage !== page && massSelected === 'all'){
      setSelectedItems([])
      setMassSelected(null)
    } else if (![null, "all", "page"].includes(massSelected) && massSelected !== currentPage) {
      setMassSelected("page")
    }

    if (setSelectedAllItems){
      setSelectedAllItems(false)
    }

    getItems(currentTab, page, currentTags, null, currentSortable, sortingMode, false)
  }

  function handleSelectAllItems(){
    setMassSelected("all")
    if (setSelectedAllItems){
      setSelectedAllItems(true)
    }
  }

  function onSelect(checked, itemId) {
    if (checked) {
      setSelectedItems(selectAll ? [...selectedItems, itemId] : [itemId])
    } else {
      setSelectedItems(selectAll ? selectedItems.filter( item => item !== itemId ) : [])
    }
  }

  function onDelete() {
    if (massSelected === 'all') {
      setItems([])
    } else {
      getItems()
    }

    setMassSelected(null)
    setSelectedItems([])
    setSelectedPages([])
  }

  function onSelectTag(tag) {
    let updatedTags = [...currentTags]

    if (!!updatedTags.find( updatedTag => updatedTag === tag )) {
      updatedTags = updatedTags.filter( updatedTag => updatedTag !== tag )
    } else {
      updatedTags.push(tag)
    }

    setCurrentTags(updatedTags)
    getItems(currentTab, 1, updatedTags)
  }

  function handleFilterMultiSelect(values, filterKey) {
    if (values.length && values[values.length - 1] !== 'all') {
      setCurrentFilters({ [filterKey]: values.filter( value => value !== 'all' ) })
    } else {
      setCurrentFilters({ [filterKey]: ['all'] })
    }
  }


  function handleColumnSelect(columnKey, checked) {
    let updatedColumns = [...tempColumns]
    let checkedColumn = updatedColumns.find(column => column.key === columnKey)

    if (checkedColumn && !checked) {
      updatedColumns = updatedColumns.filter(column => column.key !== columnKey)
    } else {
      updatedColumns = [...updatedColumns, listColumns.find(column => column.key === columnKey)]
    }
    updatedColumns = [...listColumns.filter(value => updatedColumns.find(selectedValue => selectedValue.key === value.key))]

    setTempColumns(updatedColumns)
  }

  function setShowCustomize(value) {
    if (value) {
      _setShowCustomize(true)
      setTempColumns([...currentColumns])
    } else {
      _setShowCustomize(false)
      setTempColumns([])
    }
  }

  function saveColumnSelect() {
    let updatedColumns = [...tempColumns]
    setCurrentColumns(updatedColumns)
    setShowCustomize(false)
    getItems(currentTab, 1, currentTags, updatedColumns)
  }

  function handleSort(columnType) {
    if (!loading) {
      let selectedSortable = columnType
      let mode = 'asc'

      if (!currentSortable || (currentSortable !== columnType)) {
        setCurrentSortable(columnType)
        setSortingMode('asc')

      } else if (currentSortable === columnType) {
        if (sortingMode === 'desc') {
          setCurrentSortable(null)
          setSortingMode(null)
          selectedSortable = null
          mode = null

        } else {
          setSortingMode('desc')
          mode = 'desc'
        }
      }

      getItems(currentTab, currentPage, currentTags, null, selectedSortable, mode)
    }
  }

  function sortingIcon(columnType) {
    if (columnType !== currentSortable) {
      return 'fa-solid fa-sort'
    } else if (columnType === currentSortable && sortingMode === 'asc') {
      return 'fa-duotone fa-sort-up active-icon'
    } else if (columnType === currentSortable && sortingMode === 'desc') {
      return 'fa-duotone fa-sort-down active-icon'
    }
  }

  function onDateChange({ startDate, endDate }) {
    if(startDate <= endDate) {
      setDateRange({
        ...dateRange,
        end_date: moment(endDate),
        start_date: moment(startDate)
      })
    }
  }

  function renderFilters() {
    return (
      <>
        { filters.map( (filter, index) => (
          <div key={ index } className={ `filter-item ${ filter.type === 'radio' ? 'date-range' : '' }` }>
            <div className='filter-name'>
              { filter.title }
            </div>

            <div className='filter-action'>
              { filter.type === 'select' &&
                <Select
                  style={{ width: '100%' }}
                  value={ currentFilters[filter.key] || 'all' }
                  onChange={ event => setCurrentFilters({ [filter.key]: event.target.value }) }
                >
                  <MenuItem value={ 'all' }>
                    { Website.translations.all }
                  </MenuItem>
                  { filter.options.map( (option, index) => (
                    <MenuItem key={ index } value={ option.value }>
                      { option.title }
                    </MenuItem>
                  ))}
                </Select>
              }

              { filter.type === 'multiSelect' &&
                <Select
                  multiple
                  style={{ width: '100%' }}
                  value={ currentFilters[filter.key] || ['all'] }
                  onChange={ event =>  handleFilterMultiSelect(event.target.value, filter.key) }
                  renderValue={ selectedValues => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      { selectedValues.map( selectedValue => (
                        <Chip
                          key={ selectedValue }
                          label={ selectedValue === 'all' ? Website.translations.all : filter.options.find( option => option.value === selectedValue ).title }
                        />
                      ))}
                    </Box>
                  )}
                >
                  <MenuItem value={ 'all' }>
                    <Checkbox
                      color='default'
                      checked={ !currentFilters[filter.key] || !!currentFilters[filter.key].find( option => option === 'all' ) }
                    />
                    { Website.translations.all }
                  </MenuItem>
                  { filter.options.map( (filterOption, index) => (
                    <MenuItem key={ index } value={ filterOption.value }>
                      <Checkbox
                        color='default'
                        checked={ currentFilters[filter.key] && currentFilters[filter.key].find( option => option === filterOption.value ) ? true : false }
                      />
                      { filterOption.title }
                    </MenuItem>
                  ))}
                </Select>
              }

              { filter.type === 'radio' && filter.options.map( (option, index) => (
                <div key={ index } className='date-range-radios'>
                  <input
                    type='radio'
                    value={ option.value }
                    checked={ (!currentFilters[filter.key] && index === 0) || (currentFilters[filter.key] === option.value) }
                    onChange={ event => setCurrentFilters({ [filter.key]: event.target.value }) }
                  />
                  <div className='radio-label m-l-5'>
                    { option.title }
                  </div>
                </div>
              ))}

              { filter.type === 'toggle' &&
                <div className="flex-1 flex-box content-end">
                  <Toggle
                    icons={{ unchecked: null }}
                    className='react-toggle small'
                    checked={ !loading && currentFilters[filter.key] === true }
                    onChange={ (event) => {
                      setCurrentFilters({ [filter.key]: event.target.checked })
                    }}
                  />
                </div>
              }

            </div>
          </div>
        )) }
      </>
    )
  }

  function renderColumns() {
    return (
      <>
        <div className={`quick-drop-menu-wrapper-overlay ${ showCustomize ? 'opened' : 'closed' }`} onClick={ () => setShowCustomize(false) }></div>
        <div className={`drop-menu ${ showCustomize ? 'opened animated' : 'closed animated' }`}>
          <div className="drop-item flex-box items-center content-end">
            <button
              type='button'
              className='btn flex-box'
              onClick={ saveColumnSelect }
            >
              { Website.translations.save }
            </button>
          </div>
          <div className='drop-items flex-box items-center flex-column' style={{ maxHeight: "300px", overflow: "scroll" }}>
            { listColumns.map( (column, index) => (
              <div key={ index } className="drop-item flex-box m-t-20">
                <input
                  type='checkbox'
                  className={ column.required ? 'disabled' : '' }
                  checked={ !!tempColumns.find( option => option.key === column.key ) }
                  onChange={ event => handleColumnSelect(column.key, event.target.checked) }
                  disabled={ column.required }
                />
                <div className="switch-text flex-box items-center flex-1">
                  <div className='label-title'>
                    { mergedColumnTranslations[column.label] }
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    )
  }

  function renderDateRangeFilter() {
    return (
      <div className='p-r-10'>
        <DateRangePicker
          displayFormat={() => "DD MMM YYYY"}
          endDate={ dateRange.end_date }
          endDateId="endDate"
          focusedInput={ focusedInput }
          onDatesChange={ onDateChange }
          onFocusChange={ setFocusedInput }
          orientation={ smallDevice ? 'vertical' : 'horizontal' }
          readOnly={ true }
          required={ true }
          noBorder={ true }
          startDate={ dateRange.start_date }
          startDateId="startDate"
          isOutsideRange={ (date) => date > new Date() - 1 ? (!enableFutureDates ? true : false) : false }
          minimumNights={ 0 }
          renderCalendarInfo={ ConfirmButton }
          hideKeyboardShortcutsPanel
          keepOpenOnDateSelect
          onClose={ handleApplyDateRange }
        />
      </div>
    )
  }

  function getItems(tab = currentTab, page = 1, selectedTags = currentTags, selectedColumns = null, selectedSortable = currentSortable, mode = sortingMode, selectedFilters = currentFilters, selectedHourRange = hourRange) {
    setLoading(true)

    let url

    if (enableHourFilter && showAdvancedFilterSection) {
      const tempFrom = { hour: selectedHourRange.from.hours(), minutes: selectedHourRange.from.minutes() }
      const tempTo = { hour: selectedHourRange.to.hours(), minutes: selectedHourRange.to.minutes() }
      const updatedStartDate = dateRange.start_date.clone().set({ hour: tempFrom.hour, minute: tempFrom.minutes, second: 0, millisecond: 0 }).utc().format()
      const updatedEndDate = dateRange.end_date.clone().set({ hour: tempTo.hour, minute: tempTo.minutes, second: 59, millisecond: 59 }).utc().format()

      const updatedDateRange = {
        start_date: updatedStartDate,
        end_date: updatedEndDate
      }

      url = `${ itemsPath }?page=${ page }&filter_status=${ tab }&date_range=${ JSON.stringify(updatedDateRange) }&hour_filter=true&search=${ searchValueRef.current.trim() }&sort=${ selectedSortable }&mode=${ mode }&tags=${ JSON.stringify(selectedTags) }`
    } else {
      url = `${ itemsPath }?page=${ page }&filter_status=${ tab }&date_range=${ JSON.stringify(dateRange) }&search=${ searchValueRef.current.trim() }&sort=${ selectedSortable }&mode=${ mode }&tags=${ JSON.stringify(selectedTags) }`
    }
    if (urlParams) {
      url += `&${ urlParams }`
    }

    if (enablePriceFilter && showAdvancedFilterSection) {
      url += `&price_range=${ JSON.stringify(priceRangeRef.current) }`
    }

    Object.keys(selectedFilters).forEach(filter => (
      url += `&${ filter }=${ JSON.stringify(selectedFilters[filter]) }`
    ))

    if (selectedColumns) {
      url += `&columns=${ JSON.stringify(selectedColumns) }`
    }

    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      success: res => {
        setLoading(false)
        setCurrentTab(tab)
        setItems(res.items)
        setTotalPages(res.totalPages)
        setTotalCount(res.totalCount)
        setCurrentPage(page)
        res.selectedColumns && setCurrentColumns(res.selectedColumns)
        if (updateTotalCount) {
          updateTotalCount(res.totalCount)
        }
      }
    })
  }

  function handleMassSelection(event){
    if (event.target.checked){
      setSelectedItems(initialItems => [...new Set([...initialItems, ...items.map( item => item.id)])])
      setSelectedPages(prevSelectedPages => [...prevSelectedPages, currentPage])
      setMassSelected(currentPage)
    } else {
      setSelectedItems(initialItems => initialItems.filter(itemId => !items.map(item => item.id).includes(itemId)))
      setSelectedPages(prevSelectedPages => prevSelectedPages.filter(selectedPage => selectedPage !== currentPage))
      setMassSelected(null)
    }

    if (setSelectedAllItems){
      setSelectedAllItems(false)
    }
  }

  function handleClearSelection() {
    setMassSelected(null)
    if (setSelectedAllItems){
      setSelectedAllItems(false)
    }
    setSelectedItems([])
    setSelectedPages([])
  }

  function exportTable() {
    setShowExportAlert(false)
    let formData = new FormData()
    formData.append('columns', JSON.stringify(currentColumns))
    formData.append('search', JSON.stringify(searchValue.trim()))
    formData.append('sort', JSON.stringify(currentSortable))
    formData.append('mode', JSON.stringify(sortingMode))
    if (enableHourFilter && showAdvancedFilterSection) {
      const tempFrom = { hour: hourRange.from.hours(), minutes: hourRange.from.minutes() }
      const tempTo = { hour: hourRange.to.hours(), minutes: hourRange.to.minutes() }
      const updatedStartDate = dateRange.start_date.clone().set({ hour: tempFrom.hour, minute: tempFrom.minutes, second: 0, millisecond: 0 }).utc().format()
      const updatedEndDate = dateRange.end_date.clone().set({ hour: tempTo.hour, minute: tempTo.minutes, second: 59, millisecond: 59 }).utc().format()

      const updatedDateRange = {
        start_date: updatedStartDate,
        end_date: updatedEndDate
      }

      formData.append('date_range', JSON.stringify(updatedDateRange))
      formData.append('hour_filter', true)
    } else {
      formData.append('date_range', JSON.stringify(dateRange))
    }

    if (enablePriceFilter && showAdvancedFilterSection) {
      formData.append('price_range', JSON.stringify(priceRangeRef.current))
    }

    if (currentTab) {
      formData.append('filter_status', JSON.stringify(currentTab))
    }
    Object.keys(currentFilters).forEach(filter => (
      formData.append(filter, JSON.stringify(currentFilters[filter]))
    ))

    if (massSelected != 'all'){
      formData.append('selected_ids', JSON.stringify(selectedItems))
    }

    Rails.ajax({
      type: 'POST',
      url: exportPath,
      dataType: 'json',
      data: formData,
      success: res => {
        flashMessageRef.current.show(Website.translations.exportStarted, 'success')
      }
    })
  }

  const handlePriceChange = (key, value) => {
    let updatedValue = value.replace(',', '.')
    updatedValue = updatedValue.match(priceRegex)[0]
    const newPriceRangeState = { ...priceRange, [key]: updatedValue }
    priceRangeRef.current = newPriceRangeState

    debouncedSearch()

    setPriceRange(newPriceRangeState)
  }

  function deleteItems() {
    setShowDeleteAlert(false)
    let fd = new FormData()
    if (massSelected !== 'all') {
      selectedItems.forEach( item => fd.append(`${ entity }[]`, item ))
    }

    if (currentTab) {
      fd.append('filter_status', currentTab)
    }

    Rails.ajax({
      type: 'DELETE',
      url: `${deletePath}${massSelected === 'all' ? '?delete_all=true' : ''}`,
      data: fd,
      dataType: 'json',
      success: res => {
        if (onDeleteSuccess) {
          onDeleteSuccess(res)
        }
        flashMessageRef.current.show(Website.translations.deletionStarted, 'success')
        setTimeout(onDelete, 1000)
      },
      error: res => {
        if (onDeleteError) {
          onDeleteError(res)
        } else {
          flashMessageRef.current.show(res.error, 'error')
          setTimeout( () => {
            window.location.reload()
          }, 1500)
        }
      }
    })
  }

  const handleApplyDateRange = (_, applyPressed) => {
    const daysLimitInMs = 92 * (24 * 60 * 60 * 1000)
    setFocusedInput(null)
    if (!applyPressed) {
      setDateRange(dateRangeRef.current)
    } else if (dateRange.end_date - dateRange.start_date > daysLimitInMs) {
      setDateRange(dateRangeRef.current)
      flashMessageRef.current.show(Website.translations.tooLongDateRange, 'error')
    } else {
      dateRangeRef.current = dateRange
      getItems()
    }
  }

  function ConfirmButton(){
    return(
      <div className='flex-box content-end'>
        <button
          className='btn btn-primary m-r-10 m-b-10'
          onClick={ _e => handleApplyDateRange(null, true) }
        >
          { Website.translations.apply }
        </button>
      </div>)
  }

  return (
    <div className='card'>
      <div className='card-content entity-index'>
        { headline &&
          <div className='card-header border-0'>
            <div className='card-title'>
              { headlineIcon && <i className={ `${ headlineIcon } m-r-10` } /> }
              { `${ headline }${ !hideHeadlineCount ? ` (${ totalCount })` : '' }`}
            </div>
          </div>
        }
        <div className='card-body entity-index'>
          { tabs && tabs.length > 1 &&
            <Tabs
              tabs={ tabs }
              currentTab={ currentTab }
              setCurrentTab={ tab => { if (tab !== currentTab ) getItems(tab) } }
            />
          }

          <div className='flex-box flex-wrap items-center content-space-between entity-index-heading'>
            { enableDateFilter && renderDateRangeFilter() }
            { !disableSearch &&
              <div>
                <div className='flex-box items-center m-b-5' style={{ position: 'relative' }}>
                  <button
                    type='button'
                    className='search-btn'
                    style={{ position: 'absolute', left: 10 }}
                    onClick={ () => {} }
                  >
                    <i className='fa-solid fa-magnifying-glass'/>
                  </button>
                  <input
                    type='text'
                    className='form-control form-control-solid w-250px ps-14'
                    placeholder={ 'Search' }
                    value={ searchValue }
                    onChange={ changeInput }
                  />
                </div>
              </div>
            }

            <div className='flex-1 flex-box items-center content-end'>
              { selectedItems.length > 0 &&
                <div className='m-l-15'>
                  <DropDownMenu
                    items={ dropdownActions }
                    title={ Website.translations.dropMenuAction }
                  />
                </div>
              }

              { (!!filters || !!enableDateFilter) &&
                <div className='flex-box items-center content-space-between m-l-30' style={{ gap: 10 }}>
                  <div>{ Website.translations.advanced_filters }</div>

                  <Toggle
                    icons={{ unchecked: null }}
                    className='react-toggle small'
                    checked={ showAdvancedFilterSection }
                    onChange={ _e => setShowAdvancedFilterSection(prevState => !prevState) }
                  />
                </div>
              }

            </div>
          </div>

          { showAdvancedFilterSection &&
            <div className='flex-box items-center m-t-20' style={{ background: '#f7f7f7', borderTop: '1px solid #dddddd', padding: 15 }}>
              <div className='flex-box flex-1'>
                { enableHourFilter &&
                  <div className='flex-box item-center content-space-between p-r-10 p-l-10'>
                    <MuiPickersUtilsProvider
                      utils={ MomentUtils }
                      locale={ 'en' }
                    >
                      <TimePicker
                        label={ Website.translations.from }
                        className='m-l-10 m-r-10'
                        style={{ width: 75 }}
                        timezone="Europe/Athens"
                        value={ moment(hourRange.from) }
                        onChange={ time => setHourRange(prevState => ({ ...prevState, from: time })) }
                      />
                      <TimePicker
                        label={ Website.translations.to }
                        style={{ width: 75 }}
                        timezone="Europe/Athens"
                        value={ moment(hourRange.to) }
                        onChange={ time => setHourRange(prevState => ({ ...prevState, to: time })) }
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                }
              </div>

              { enablePriceFilter &&
                <div className='flex-box items-center content-space-between p-r-10 p-l-10'>
                  <div className='input-field price-prefix' style={{ margin: 0, padding: 0 }}>
                    <div className="prefix-label" style={{ left: 10, top: '23%' }}>
                      { '€' }
                    </div>

                    <input
                      type='number'
                      className='form-control form-control-solid'
                      style={{ width: 100, margin: 0 }}
                      value={ priceRange.minimum ?? 0 }
                      onChange={ e => handlePriceChange('minimum', e.target.value) }
                    />
                  </div>

                  <i className='fa-light fa-arrow-right m-l-5 m-r-5' />

                  <div className='input-field price-prefix' style={{ margin: 0, padding: 0 }}>
                    <div className="prefix-label" style={{ left: 10, top: '23%' }}>
                      { '€' }
                    </div>

                    <input
                      type='number'
                      className='form-control form-control-solid'
                      style={{ width: 100, margin: 0 }}
                      value={ priceRange.maximum ?? 100000 }
                      onChange={ e => handlePriceChange('maximum', e.target.value) }
                    />
                  </div>
                </div>
              }

              { filters &&
                <div className='advanced-filters-wrapper m-r-10 m-l-10' style={{ position: 'relative' }}>
                  { showFilters && <div className='advanced-filters-overlay' onClick={ ()=> setShowFilters(false) }></div> }
                  <div
                    id='advanced-filters-button'
                    className='flex-box items-center'
                    style={{ cursor: 'pointer' }}
                    onClick={ () => { if (!loading) setShowFilters(!showFilters) }}
                  >
                    <i className='fa-light fa-bars-filter m-r-5'/>
                    { Website.translations.filters }
                  </div>

                  { showFilters &&
                    <div className='advanced-filters-drop-menu'>
                      <div className='filter-item-wrapper'>
                        <div className='filter-title'>
                          <b>{ Website.translations.filters }:</b>
                        </div>

                        { renderFilters() }
                      </div>
                    </div>
                  }
                </div>
              }
            </div>
          }

          { !disableSelectTags && tags && tags.length > 0 &&
            <div className='flex-box items-center content-space-between'>
              <div className='flex-box flex-wrap'>
                { tags.map( tag => {
                  const isSelected = !!currentTags.find( currentTag => currentTag === tag.key )

                  return (
                    <div
                      key={ tag.key }
                      className={ `filter-tag flex-box items-center ${ isSelected ? 'selected' : '' }` }
                      onClick={ () => onSelectTag(tag.key) }
                    >
                      { tag.icon &&
                        <i className={ tag.icon } style={{ marginRight: 5 }}/>
                      }
                      { tag.title }
                    </div>
                  )
                })}
              </div>
            </div>
          }

          { totalPages > 1 && (massSelected === currentPage || massSelected === "all") &&
            <div className='flex-box content-center items-center p-t-5 p-b-5' style={{ backgroundColor: '#f9f9f9' }}>
              { massSelected === currentPage && <>({ items.length }) { Website.translations.itemsSelected } <u style={{ cursor: "pointer" }} onClick={ handleSelectAllItems }>{ Website.translations.selectedAllItems }</u></>}
              { massSelected === "all" && <>{ Website.translations.allItemsSelected } ({ totalCount }) &nbsp;<u style={{ cursor: "pointer" }} onClick={ handleClearSelection }>{ Website.translations.clearSelection }</u></> }
            </div>
          }

          <div className='m-t-20' style={{ minHeight: 200 }}>
            { loading && <EntityLoader /> }

            <div className='table-wrapper flex-box'>
              { (!disableDelete || isExportable) &&
                <div className='table-left flex-box flex-column'>
                  { !loading && items.length > 0 &&
                    <div className='heading delete-all-wrapper'>
                      <label>
                        <button type='button'>
                          <input
                            type='checkbox'
                            onChange={ event => handleMassSelection(event) }
                            style={ !(selectAll && (!disableDelete || isExportable)) ? { visibility: 'hidden' } : {} }
                            disabled={ !(selectAll && (!disableDelete || isExportable)) }
                            checked={ !!(selectedPages.includes(currentPage)) }
                          />
                          <span/>
                        </button>
                      </label>
                    </div>
                  }

                  { !loading && items.length > 0 &&
                    <div className='items flex-box flex-column'>
                      { items.map( item => (
                        <div key={ item.id } className='item-sticky flex-1'>
                          <label>
                            <input
                              type='checkbox'
                              checked={ !!selectedItems.find( selectedItem => selectedItem === item.id ) }
                              onChange={ event => onSelect(event.target.checked, item.id) }
                              style={ ((disableDelete || item.disableDelete) && !isExportable) ? { visibility: 'hidden' } : {} }
                              disabled={ (disableDelete || item.disableDelete) && !isExportable }
                            />
                            <span/>
                          </label>
                        </div>
                      ))}
                    </div>
                  }
                </div>
              }

              { !loading && items.length > 0 &&
                <div className='table-middle'>
                  <div className='headers flex-box'>
                    { currentColumns.map( (tableHeading, index) => (
                      <div key={ index } className='item flex-1 bold'>
                        { mergedColumnTranslations[tableHeading.label] }
                        { tableHeading.sortable &&
                          <i
                            className={ `m-l-10 ${ sortingIcon(tableHeading.key) }` }
                            style={{ cursor: 'pointer' }}
                            onClick={ () => handleSort(tableHeading.key) }
                          />
                        }
                      </div>
                    ))}
                  </div>
                  <div className='rows flex-box flex-column'>
                    { items.map( item => (
                      <div key={ item.id } className='table-row flex-box'>
                        { itemRenderer(item, currentColumns) }
                      </div>
                    ))}
                  </div>
                </div>
              }

              <div className='table-right'>
                <div className='heading'/>
                { !loading && items.length > 0 &&
                  <div className='items flex-box flex-column'>
                    { items.map( item => (
                      <div key={ item.id } className='item-sticky flex-box content-space-between' style={{ position: 'relative' }}>
                        { actions && actions.map( (action, index) => {
                          if (action.dropMenu) {
                            return (
                              <DropMenu
                                key={ index }
                                item={ item }
                                dropMenuItems={ dropMenuItems }
                                selectedItem={ selectedDropMenuItem }
                                setSelectedItem={ setSelectedDropMenuItem }
                              />
                            )

                          } else if (!action.hidden || (action.hidden && !action.hidden(item))) {
                            return (
                              <a
                                key={ index }
                                className='link flex-1'
                                href={ action.url ? action.url(item) : '' }
                                target={ action.target || '_self' }
                                onClick={ event => action.onClick ? action.onClick(event, item) : {} }
                              >
                                <i className={ `fa-solid ${ action.icon }` }/>
                              </a>
                            )

                          } else {
                            return null
                          }
                        })}
                      </div>
                    ))}
                  </div>
                }
              </div>
            </div>

            { !loading && items.length === 0 &&
              <EmptyGrid
                iconName={ icon }
                noResourcesTitle={ noResourcesTitle }
                onReload={ fetchOnStart ? getItems : null }
                disableReload={ disableReload }
              />
            }

          </div>

          { totalPages > 1 && !loading && items.length > 0 &&
            <div className='row'>
              <Pagination
                currentPage={ currentPage }
                totalPages={ totalPages }
                changePage={ page => handleChangePage(page) }
              />
            </div>
          }

        </div>
      </div>

      { showDeleteAlert && customDeleteAlert && customDeleteAlert(selectedItems, deleteItems, onCancel) }
      { showDeleteAlert && !customDeleteAlert &&
        <ModalAlert
          alert={ Website.translations.deleteAlert }
          onSelect={ deleteItems }
          onClose={ () => setShowDeleteAlert(false) }
        />
      }

      { showExportAlert &&
        <ModalAlert
          alert={ Website.translations.exportAlert }
          onSelect={ exportTable }
          onClose={ () => setShowExportAlert(false) }
        />
      }

      <FlashMessage ref={ flashMessageRef }/>
    </div>
  )
}
