import React, { useState } from 'react'
import DynamicEntityIndex from '../shared/DynamicEntityIndex'
import CreateButton from '../shared/CreateButton'

export default function Receipts({
  appProps, receipts, initialTotalPages, itemsPath, selectedColumns, initialTotalCount
}) {
  const [receiptsCount, setReceiptsCount] = useState(initialTotalCount)

  const actions = [
    {
      url: item => {
        const adminShowPath = `/admin/${ appProps.currentLocale }/mids/${ item.subaccountMid }/receipts/${ item.apiUid }`
        const letmeinShowPath = `/letmein/${ appProps.currentLocale }/meraccs/${ item.accountApiUid }/mids/${ item.subaccountMid }/receipts/${ item.apiUid }`

        const showPath = appProps.merchantsPath ? letmeinShowPath : adminShowPath
        return showPath
      },
      icon: 'fa-eye'
    },
    {
      url: item => item.iviewUrl,
      icon: 'fa-arrow-up-right-from-square'
    }
  ]

  const renderReceipt = (receipt, columns) => (
    <>
    { columns.map( column => {
      const key = column.key
      return (
        <div key={ column.key } className='item flex-1'>
          <div>
            { receipt[key] || '-' }
          </div>
        </div>
      )
    })}
  </>
  )

  return (
    <>
      <CreateButton
        pageTitle={ Website.translations.receipts }
        breadcrumbs={ appProps.breadcrumbs }
        count={ receiptsCount }
        canCreate={ false }
      />

      <DynamicEntityIndex
        appProps={ appProps }
        initialItems={ receipts }
        initialTotalPages={ initialTotalPages }
        selectAll={ false }
        disableCustomization={ true }
        disableDelete={ true }
        entity={ "receipts" }
        tabs={ [] }
        tableHeadings={ selectedColumns }
        itemRenderer={ renderReceipt }
        itemsPath={ itemsPath }
        noResourcesTitle={ Website.translations.noReceipts }
        actions={ actions }
        columnTranslations={ Website.translations.columns }
        initialTotalCount={ initialTotalCount }
        updateTotalCount={ setReceiptsCount }
      />
    </>
  )
}
