import React, { useState } from 'react'
import FormInput from '../shared/FormInput'
import SaveFixedButton from '../shared/SaveFixedButton'
import CreateButton from '../shared/CreateButton'

export default function EditDirectorSettings({ appProps, settings, updatePath }) {
  const [emailSettingsChanged, setEmailSettingsChanged] = useState(false)
  const [emailSettings, setEmailSettings]               = useState(settings.filter(item => item.key.startsWith('smtp_') && item.key !== 'smtp_enable_starttls_auto'))
  const [providerIdSetting, setProviderIdSetting]       = useState(settings.find(item => item.key == 'invoicing_software_provider_id'))
  const [googleCloudSettings, setGoogleCloudSettings]   = useState(settings.filter(item => item.key.startsWith('google_') && item.key !== 'google_storage_app_expires_at'))
  const [saveButtonRef, setSaveButtonRef]               = useState(null)

  const renderEmailSetting = setting => {
    const label = setting.key.split('_').map(item => item[0].toUpperCase() + item.slice(1)).join(' ')
    const type = label === 'Smtp Password' ? 'password' : 'text'

    return (
      <FormInput
        key ={ setting.id }
        label={ label }
        type={ type }
        value={ setting.value }
        onChange={ e => handleSettingChange(e, setting.key) }
      />
    )
  }

  const handleSettingChange = (e, key) => {
    const settingIndex = emailSettings.findIndex(item => item.key === key)

    setEmailSettings(prevState => ( [
      ...prevState.slice(0, settingIndex),
      { ...prevState[settingIndex], value: e.target.value },
      ...prevState.slice(settingIndex + 1)
    ]))

    setEmailSettingsChanged(true)
    saveButtonRef.setSaveableStatus()
  }

  const handleProviderIdChange = (e) => {
    setProviderIdSetting(prevState => ({ ...prevState, value: e.target.value }))

    saveButtonRef.setSaveableStatus()
  }

  function requestGoogleTokens() {
    const form = document.createElement('form')
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content")
    const hiddenField = document.createElement('input')

    form.method = 'post'
    form.action = '/auth/google_cloud_auth'
    hiddenField.type = 'hidden'
    hiddenField.name = "authenticity_token"
    hiddenField.value = csrf
    form.appendChild(hiddenField)
    document.body.appendChild(form)
    form.submit()
    document.body.removeChild(form)
  }

  const save = () => {
    const fd = new FormData()

    emailSettings.forEach((setting, index) => {
      fd.append(`settings[${ index }][key]`, setting.key)
      fd.append(`settings[${ index }][value]`, setting.value)
    })
    fd.append('validate_email_settings', emailSettingsChanged)

    fd.append(`settings[${ emailSettings.length }][key]`, providerIdSetting.key)
    fd.append(`settings[${ emailSettings.length }][value]`, providerIdSetting.value)

    saveButtonRef.setSavingStatus()

    Rails.ajax({
      type: 'PATCH',
      url: updatePath,
      dataType: 'json',
      data: fd,
      success: res => {
        saveButtonRef.setSuccessStatus()
        setEmailSettings(res.settings.filter(item => item.key.startsWith('smtp_') && item.key !== 'smtp_enable_starttls_auto' ))
        setEmailSettingsChanged(false)
      },
      error: res => {
        saveButtonRef.setErrorStatusWithMessage(res.error)
      }
    })
  }

  return (
    <>
      <CreateButton
        pageTitle={ Website.translations.directorSettings }
        breadcrumbs={ appProps.breadcrumbs }
      />

      <div className='m-b-30'>
        <h3>{ Website.translations.emailSettings }</h3>
      </div>

      <div className='card m-b-30'>
        { emailSettings.map(renderEmailSetting) }
      </div>

      <div className='m-b-30'>
        <h3>{ Website.translations.invoicingProviderSettings }</h3>
      </div>

      <div className='card m-b-30'>
        <FormInput
          key ={ providerIdSetting.id }
          label={ 'Invoicing Software Provider Id' }
          type={ 'text' }
          value={ providerIdSetting.value }
          onChange={ e => handleProviderIdChange(e) }
        />
      </div>

      <div className="card">
        <div className="card-content">
          <span className='fs-6 fw-semibold form-label'>
            Initialize Google Cloud API
          </span>
          <div className="card-body">
            <div className="row">
              { googleCloudSettings.length == 0 ?
                <div className='flex-box content-end items-center'>
                  <button className='pointer btn btn-primary m-r-20' onClick={requestGoogleTokens}>
                    <b>
                      Get Google Cloud Token
                    </b>
                  </button>
                </div>
                :
                ( googleCloudSettings.find(s => s.value == '') ?
                  <div className='flex-box content-space-between items-center'>
                    <div className="input-field">
                      <b>Authorization process hasn't completed successfully.</b><br/><br/>
                      Please remove Oauth App from Google account's data & privacy settings and start authorization process again.
                    </div>
                    <div>
                      <button className='pointer btn btn-primary m-r-20' onClick={requestGoogleTokens}>
                        <b>
                          Get Google Cloud Token
                        </b>
                      </button>
                    </div>
                  </div>
                  :
                  <div className="input-field">
                    <b>Access token granted.</b>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>

      <SaveFixedButton
        onSave={ save }
        entity={ Website.translations.directorSettings }
        ref={ ref => setSaveButtonRef(ref) }
      />
    </>
  )
}
