import React from 'react'

export default function Index({ appProps }) {

  return (
    <>
      <iframe src={ '/sidekiq' } width='100%' style={{ minHeight: '85vh' }} title="Async Jobs"></iframe>
    </>
  )
}
